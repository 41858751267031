.pagination-section {
    padding-bottom: 30px;
}

.pagination-dropdown .ant-dropdown-menu-item {
    text-align: center;
}

.onboarding-information {
    margin: 0px 0px 16px 0px;
    padding-left: 56px;
    padding-top: 14px;

    background: #EDFAFE;
    /* Gray-background */

    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    /* Dropshadow */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.ant-alert-info .ant-alert-icon {
    color: #00B379;
}

.table-tag-container {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
}

.ModelListView-filter-dropdown {
    margin-top: 0;
    min-width: 800px;
    margin-bottom: 24px;
}
